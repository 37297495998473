import {useEffect, useState} from "react";

const useHover = (ref) => {
    const [hovered, setHovered] = useState(false);
    const enter = () => {setHovered(true)};
    const leave = () => {setHovered(false)};

    useEffect(() => {
        const el = ref.current; // cache external ref value for cleanup use
        if (el) {
            el.addEventListener("mouseenter", enter);
            el.addEventListener("mouseleave", leave);

            return () => {
                el.removeEventListener("mouseenter", enter);
                el.removeEventListener("mouseleave", leave);
            };
        }
    }, [ref]);

    return hovered;
};

export default useHover;
