import React, {useState, useEffect, useRef} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Pagination, A11y, Autoplay, Navigation} from 'swiper';
import 'swiper/swiper-bundle.min.css'

import './carousels.css'
import useHover from "../utils/use-hover";
import {LGHeader} from "./typography";

SwiperCore.use([Pagination, A11y, Autoplay, Navigation]);

export const SetActiveCarousel = ({children, activeSlideIndex, className}) => {
  const [swiper, setSwiper] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    if (swiper) {
      swiper.slideToLoop(activeSlideIndex)
    }
  }, [activeSlideIndex])

  return (
    <div className={`arrows-carousel set-active-carousel px-8 md:px-12 py-8 w-full overflow-hidden ${className}`}>
      <div className="md:px-12 pt-8">
        <Swiper
          ref={ref}
          slideToClickedSlide={true}
          spaceBetween={48} // 2rem
          onSwiper={setSwiper}
          autoplay={false}
          slidesPerView={2}
          breakpoints={{
            640: {
              slidesPerView: 2,
              slidesPerGroup: 2
            },
            900: {
              slidesPerView: 3,
              slidesPerGroup: 3
            }
          }}
          navigation
          loop
        >
          {children.map((slide, index) => (
            <SwiperSlide key={index}>
              {slide}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export const ArrowsCarousel = ({children, headline}) => {
  const [swiper, setSwiper] = useState(null);
  const ref = useRef(null);
  const hovered = useHover(ref);
  useEffect(() => {
    if (swiper) {
      if (hovered) {
        swiper.autoplay.stop();
      } else {
        swiper.autoplay.start();
      }
    }
  }, [swiper, hovered])
  return (
    <div className="arrows-carousel px-8 md:px-12 py-8 w-full overflow-hidden">
      <LGHeader className="md:pl-4">{headline}</LGHeader>
      <div className="md:px-12 pt-8">
        <Swiper
          ref={ref}
          slidesPerView={1}
          spaceBetween={48} // 2rem
          onSwiper={setSwiper}
          autoplay={{delay: 3000, disableOnInteraction: true}}
          breakpoints={{
            640: {
              slidesPerView: 2,
              slidesPerGroup: 1
            },
            768: {
              slidesPerView: 3,
              slidesPerGroup: 1
            }
          }}
          navigation
          loop
        >
          {children.map((slide, index) => (
            <SwiperSlide key={index}>
              {slide}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export const SplitArrowCarousel = ({children}) => (
  <div className="split-arrow-carousel">
    <Swiper
      slidesPerView={1}
      navigation
      loop
    >
      {children.map((slide, index) => (
        <SwiperSlide key={index}>
          {slide}
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
)

export const DotsCarousel = ({images}) => {
  const [swiper, setSwiper] = useState(null);
  const ref = useRef(null);
  const hovered = useHover(ref);
  useEffect(() => {
    if (swiper) {
      if (hovered) {
        swiper.autoplay.stop();
      } else {
        swiper.autoplay.start();
      }
    }
  }, [swiper, hovered])
  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      onSwiper={setSwiper}
      pagination={{clickable: true}}
      autoplay={{delay: 3000, disableOnInteraction: true}}
      ref={ref}
    >
      {images.map((slide, index) => (
        <SwiperSlide key={index}>
          {["video/mp4", "video/m4v"].indexOf( slide.file.contentType ) !== -1 ?
            <video autoPlay muted loop className="h-full">
              <source src={slide.file.url} type={slide.file.contentType}/>
            </video>
            :
            <img className="h-full w-full object-cover" key={index} alt={slide.description} src={slide.file.url}/>}
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
